.summary-box-container {
  font-family: "FiraSans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #004e7b;
}

@media (min-width: 1138px) {
  .summary-box-container-trim-right {
    margin-right: 16.6667%;
  }
}

.summary-box-page-container-hide {
  display: none;
}

.summary-box-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 2px solid #e6e6e6;
  z-index: 2;
}

.summary-box-separator {
  background-color: #e6e6e6;
  border: none;
  height: 2px;
  margin: 0;
}

.app-content-opaque {
  opacity: 0.3;
}

.summary-box-header {
  display: flex;
  font-weight: 600;
  float: left;
  clear: left;
  padding: 1em 0;
}

.summary-box-toggle,
.summary-box-toggle:hover {
  background-color: transparent;
  border-bottom: none;
}

.summary-box-toggle {
  display: none;
}

.summary-box-toggle .a-icon {
  margin: -5px 4px -5px 0;
  color: #004d8c;
  font-size: 24px;
  transform: rotate(90deg);
}

.summary-box-header__item-amount-mobile {
  display: none;
}

.summary-box-list {
  margin: 0;
  padding: 1em 0 1em 3em;
  display: flex;
  flex-direction: row;
  list-style-type: none;
}

.summary-box-list-item {
  padding: 0 8px;
}

.summary-box-list-item-primary {
  font-weight: 600;
}

.summary-box-list-item-secondary::before {
  content: "•";
  color: #004d8c;
  display: inline-block;
  width: 0.5em;
  margin-left: -0.5em;
  font-weight: 600;
}

.summary-box-media-br br {
  content: " ";
  display: inline;
}

.summary-box-list-item span {
  padding: 0 2px;
}

@media screen and (max-width: 770px) {
  .summary-box-header {
    float: none;
    max-width: 400px;
  }

  .summary-box-header-label {
    display: flex;
    width: 60%;
  }

  .summary-box-header__item-amount-mobile {
    display: inline;
    float: right;
  }

  .summary-box-toggle {
    display: flex;
  }

  .summary-box-list {
    padding: 0;
    flex-direction: column;
    justify-content: space-around;
    height: 0;
    overflow: auto;
    transition: all 0.2s ease-in-out;
  }

  .summary-box-list--open {
    padding-top: 1em;
    height: auto;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    margin-right: 0.2em;
    -webkit-box-shadow: 0 0 30px 0 rgba(29, 64, 84, 0.2);
    box-shadow: 2px 2px 10px 0 rgba(29, 64, 84, 0.2);
  }

  .summary-box-list-item {
    padding: 0 0 1em 1em;
    display: flex;
    align-self: flex-start;
    max-width: 400px;
    width: 100%;
    /* (on mobile) hide until user opens pulldown */
    opacity: 0;
    visibility: hidden;
  }

  .summary-box-list-item-secondary {
    width: 60%;
  }
  .summary-box-list-item-secondary::before {
    margin-left: 0;
  }

  .summary-box-list-item--visible {
    /* (on mobile) show list items - user clicked pulldown open */
    opacity: 1;
    visibility: visible;
  }

  .summary-box-media-br br {
    content: normal;
    display: block;
  }
}
