/* avoid using this file, any style changes should be done in nitro components library directly */
.calculator_fix {
  max-width: 100%;
}

.summery_box {
  background-color: #edf4f6;
  padding: 16px;
  margin-bottom: 25px;
}

.line {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Vista Slab OT Medium, Helvetica, Arial, sans-serif;
}

.term_container {
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #004d8c;
  min-width: 132px;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: #b4b4b4;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 55px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  width: auto;
  min-width: 115px;
  outline: 0px !important;
  font-size: 30px;
  font-size: 1.875rem;
  font-family: vistaslabalt;
}

.term_button {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;

  cursor: pointer;
}

.term_left {
  margin-left: 8px;
}
.term_right {
  margin-right: 8px;
}

.term_center {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.split {
  align-self: stretch;
  background-color: #b4b4b4;
  width: 1px;
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 10px;
}

.g-cell {
  --antd-wave-shadow-color: rgb(0, 78, 120);
}

.g-cell .ant-slider-handle {
  border: solid 10px #004d8c;
  background-color: #004d8c;
  top: 1px;
}

.g-cell .ant-slider-handle:focus {
  border-color: #004d8c;
}

.g-cell .ant-slider-dot-active {
  border-color: #004d8c;
  background-color: #004d8c;
}

.g-cell .ant-slider-handle.ant-tooltip-open {
  border-color: #004d8c;
}

.g-cell .ant-slider-mark-text {
  width: 80px;
  font-family: "FiraSans", sans-serif;
  color: #004d8c !important;
  font-weight: 600;
  white-space: nowrap;
}

.ant-slider-tooltip .ant-tooltip-inner {
  font-family: "FiraSans", sans-serif;

  color: #004d8c !important;
  font-weight: 600;
  background-color: #edf4f6 !important;
  min-width: 10px;
}

.g-cell .ant-slider-track {
  background-color: #004d8c;
}

.g-cell .ant-slider:hover .ant-slider-track {
  background-color: rgb(0, 78, 120);
}

.slider1 .ant-tooltip-inner {
  background-color: transparent;
  color: black;
  border: none;
}

.slider_container-1 {
  margin: 0px 18px 0px 9px;
}

.g-cell .ant-slider-rail {
  background-color: #e6e6e6;
}

.slider_container-2 {
  margin: auto;
}

@media only screen and (max-width: 753px) {
  .ant-slider-tooltip .ant-tooltip-inner {
    margin-bottom: 0px;
  }

  .slider-divider-media {
    height: 28px;
  }
}

.tooltip_term .ant-tooltip-inner {
  background-color: transparent;
  color: black;
  box-shadow: transparent;
}

.tooltip_term .ant-tooltip-arrow::before {
  background-color: transparent;
}

.t-step .ant-tooltip {
  z-index: 1;
}

.t-step .ant-tooltip-placement-top .ant-tooltip-arrow {
  display: none;
}

.t-step .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: rgb(0, 78, 120);
}

@media only screen and (max-width: 500px) {
  .line .a-input__wrapper {
    width: 9em;
  }
}

.monthly-triangle {
  height: 9px;
}

.container-monthly {
  font-family: "FiraSans", sans-serif;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: rgb(0, 78, 120);
}

.container-monthly .monthly-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.container-monthly .triangle-left {
  margin-right: auto;
}

.container-monthly .triangle-right {
  margin-left: auto;
}

.monthly-fixed-label {
  display: inline-block;
  width: 30px;
  font-weight: 200;
}

.line input {
  font-size: 30px;
  font-size: 1.875rem;
  font-family: vistaslabalt;
}

.push-right {
  margin-left: auto;
}

.o-footer__wrap .a-link {
  margin-right: 15px;
}

.a-text {
  margin-bottom: 0px;
}

.ant-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  height: 12px;
  margin: 14px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle:focus {
  border-color: #baec9f;
  outline: none;
  -webkit-box-shadow: 0 0 0 5px #baec9f;
  box-shadow: 0 0 0 5px #baec9f;
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #8cc8ff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}

.ant-tooltip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: "";
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
  -ms-transform: translateY(-6.53553391px) rotate(45deg);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
  -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
  -ms-transform: translateX(6.53553391px) rotate(45deg);
  transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
  -ms-transform: translateX(-6.53553391px) rotate(45deg);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
  -webkit-box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
  -ms-transform: translateY(6.53553391px) rotate(45deg);
  transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
